body {
  margin: 0;
  font-family: "Times";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: inherit;
}

code {
  font-family: "Times";
}

.centerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* max-height: 100vh; */
}
